import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: false,
    isLoginError: false,
    msgLoginError: null,
    cleanerInfo: null,
    userInfo: null,
    bookingInfo: null,
    invoices: [],
    returnedValue: null,
    responseDataLength: 0
  },
  mutations: {
    loginSuccess(state, payload) {
      state.isLogin = true;
      state.isLoginError = true;
      state.userInfo = payload.data[0];
      state.cleanerInfo = payload.data[1];
      state.bookingInfo = payload.data[2];
      state.responseDataLength = payload.data.length;
    },

    insertSuccess(state, payload) {
      state.bookingInfo = payload.data[1];
    },

    cancelSuccess(state, payload) {
      state.bookingInfo = payload.data[1];
    },
    loginError(state, payload)
    {
      state.isLoginError = true;
      state.msgLoginError = payload;
      state.isLogin = false;
      state.cleanerInfo = null;
      state.bookingInfo = null;
      state.userInfo = null;
      
    },
    logout(state) {
      state.isLoginError = false;
      state.isLogin = false;
    }
  },
  actions: {
    login({commit}, loginObj) {

      //console.log(loginObj);    // Login에서 넘어온 로그인 정보(이멜,패스워드)
      // C:\Bitnami\wampstack-7.2.34-0\apache2\htdocs\api

      var date = new Date();
      var today = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate() + " 00:00:00";

      var tomorrowDate = new Date();
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      var tomorrow = tomorrowDate.getFullYear()+'-'+(tomorrowDate.getMonth()+1)+'-'+tomorrowDate.getDate() + " 00:00:00";

      var beginHour = new Date(date);
      beginHour = beginHour.getHours();

      axios.post('/api/action.php', { 
          action: 'fetchSingle',
          phoneno: loginObj.phoneno,
          password: loginObj.password,
          today: today,
          tomorrow: tomorrow,
          appointmentTimeBegin: beginHour
      })
      .then( response => {
          console.log( "loginSuccess => " );
          console.log( response.data[2]);
          if ( response.data[0] == null )
          {
            this.state.returnedValue = response;
            commit('loginError', "Wrong login info..");
          }
          else {
          
            this.state.returnedValue = response;
            commit('loginSuccess', response);
            router.push( {name: "Booking"});      // login success -> move to booking
          }
      })
      .catch( error => {
          commit('loginError', error);
      });
    },

    submitBooking( {state}, bookingDateHour)
    {
      //console.log(bookingDateHour.bookingDate);
      console.log(state.isLogin);
      if ( bookingDateHour.bookingHour === null )
      {
        console.log("Empty");
      }
      else
      {

        var date = new Date();
        var today = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate() + " 00:00:00";
  
        var tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        var tomorrow = tomorrowDate.getFullYear()+'-'+(tomorrowDate.getMonth()+1)+'-'+tomorrowDate.getDate() + " 00:00:00";
  
        var beginHour = new Date(date);
        beginHour = beginHour.getHours();


        var _apptTimeBegin = "";
        var _apptTimeEnd = "";
        const str = bookingDateHour.BookingTime;
        if ( str.includes("-"))
        {
          const words = str.split('-');
          _apptTimeBegin = words[0];
          _apptTimeEnd = words[1];
        }

        if ( _apptTimeBegin.includes("PM"))
        {
          _apptTimeBegin = _apptTimeBegin.replace("PM","");
          _apptTimeBegin = parseInt( _apptTimeBegin ) + 12;
        }
        else if ( _apptTimeBegin.includes("AM"))
        {
          _apptTimeBegin = _apptTimeBegin.replace("AM","");
        }

        if ( _apptTimeEnd.includes("PM"))
        {
          _apptTimeEnd = _apptTimeEnd.replace("PM","");
          _apptTimeEnd = parseInt( _apptTimeEnd ) + 12;
        }
        else if ( _apptTimeEnd.includes("AM"))
        {
          _apptTimeEnd = _apptTimeEnd.replace("AM","");
        }

        console.log(bookingDateHour.CreateDate);

        axios.post('/api/action.php', { 
          action: 'insertBooking',
          storeCode: bookingDateHour.StoreCode,
          userID:bookingDateHour.UserID,
          apptDate: bookingDateHour.BookingDate + ' 00:00:00',
          apptTimeBegin: _apptTimeBegin,
          apptTimeEnd: _apptTimeEnd,
          memo: bookingDateHour.Memo,
          createDate: bookingDateHour.CreateDate,
          cancelDate: null,
          status: 1,
          active: 1,
          today: today,
          tomorrow: tomorrow,
          appointmentTimeBegin: beginHour
        })
        .then( response => {
          console.log("insertSuccess => "  );
          console.log( response.data[1]);
    
          this.commit('insertSuccess', response);
        })
      }
    },

    checkAvailableBooking( {state, commit} )
    {
      var date = new Date();
      var today = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate() + " 00:00:00";

      var tomorrowDate = new Date();
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      var tomorrow = tomorrowDate.getFullYear()+'-'+(tomorrowDate.getMonth()+1)+'-'+tomorrowDate.getDate() + " 00:00:00";

      var beginHour = new Date(date);
      beginHour = beginHour.getHours();

      axios.post('/api/action.php', { 
        action: 'fetchBooking',
        storeCode: state.userInfo.StoreCode,
        userID: state.userInfo.ID,
        today: today,
        tomorrow: tomorrow,
        appointmentTimeBegin: beginHour
    })
    .then( response => {
        console.log(response);
        if ( response.data[0] == null )
        {
          alert("Not Existed");
        }
        else {
          alert("Exist");
        }
    })
    .catch( error => {
        commit('loginError', error);
    });
    },

    cancelBooking( {state}, cancelInfo)
    {
      console.log( state.isLogin);
      console.log( cancelInfo.cancelDate + " " + cancelInfo.bookingID);
      if ( cancelInfo != null )
      {
          var date = new Date();
          var today = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate() + " 00:00:00";
    
          var tomorrowDate = new Date();
          tomorrowDate.setDate(tomorrowDate.getDate() + 1);
          var tomorrow = tomorrowDate.getFullYear()+'-'+(tomorrowDate.getMonth()+1)+'-'+tomorrowDate.getDate() + " 00:00:00";
    
          var beginHour = new Date(date);
          beginHour = beginHour.getHours();


          axios.post('/api/action.php', { 
            action: 'cancelBooking',
            bookingID : cancelInfo.bookingID,
            cancelDate : cancelInfo.cancelDate,
            storeCode: state.userInfo.StoreCode,
            userID: state.userInfo.ID,
            today: today,
            tomorrow: tomorrow,
            appointmentTimeBegin: beginHour
          
          })
          .then( response => {
            console.log("cancelSuccess => "  );
            console.log( response.data[1]);
      
            this.commit('cancelSuccess', response);
          })
      }
    },



  },
  modules: {
  }
})
