<template>
  <v-container fill-height style=max-width:450px>
      <v-row>
        <v-row justify="center" align="center"  no-gutters>
            <v-col >
                <v-alert
                    :value="isLoginError"
                    dense
                    outlined
                    type="error"
                    >
                    Please check the phone no. and password.
                </v-alert>
            </v-col>
        </v-row>
        <v-row justify="center" align="center"  no-gutters>
            <v-card min-width="450px">
                <v-toolbar flat >
                    <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                <div class="pa-3">
                    <v-text-field
                        v-model="phoneno"
                        label="Phone No.(10digit, No dash)"
                        >
                    </v-text-field>
                    <v-text-field
                        v-model="password"
                        type="password"
                        label="Password"
                    >
                    </v-text-field>
                    <v-btn 
                        color="primary"
                        large
                        block
                        depressed
                        @click="login(
                            {
                                phoneno: phoneno,
                                password: password
                            }
                        )"
                    >Login</v-btn>
                </div>
            </v-card>
        </v-row>
      </v-row>

  </v-container>
</template>

<script>


export default {
    data() {
        return {
            phoneno: null,
            password: null,
        }
    },
    computed: {
        isLogin() {
            return this.$store.state.isLogin
        },
        isLoginError() {
            return this.$store.state.isLoginError
        },
        returnedValue() {
            return this.$store.state.returnedValue
        }
    },
    methods: {
        login(parInfo) {
            console.log(parInfo);
            this.$store.dispatch('login', parInfo);
            console.log(this.$store.state.returnedValue);
            console.log(this.$store.state.isLoginError);

        }
    }
}
</script>

<style>

</style>
<style lang="stylus">

</style>